import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import Loader from './Loader';

function CardExample() {
  return (
    <div className='pt-5'>
      <div className="d-flex justify-content-around flex-wrap mt-5 pt-5 align-start">
      <Card style={{ width: '18rem' , margin:'10px'}}>
        <div style={{width:'100%',height:'100px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#ccc'}}><Loader /></div>
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> 
          </Placeholder>
          <Placeholder.Button variant="warinng" style={{backgroundColor:'#EC8106'}} xs={6} />
        </Card.Body>
      </Card>

      <Card style={{ width: '18rem' , margin:'10px'}}>
        <div style={{width:'100%',height:'100px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#ccc'}}><Loader /></div>
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> 
          </Placeholder>
          <Placeholder.Button variant="warinng" style={{backgroundColor:'#EC8106'}} xs={6} />
        </Card.Body>
      </Card>

      <Card style={{ width: '18rem' , margin:'10px'}}>
        <div style={{width:'100%',height:'100px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#ccc'}}><Loader /></div>
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> 
          </Placeholder>
          <Placeholder.Button variant="warinng" style={{backgroundColor:'#EC8106'}} xs={6} />
        </Card.Body>
      </Card>

      <Card style={{ width: '18rem' , margin:'10px'}}>
        <div style={{width:'100%',height:'100px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#ccc'}}><Loader /></div>
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> 
          </Placeholder>
          <Placeholder.Button variant="warinng" style={{backgroundColor:'#EC8106'}} xs={6} />
        </Card.Body>
      </Card>

      <Card style={{ width: '18rem' , margin:'10px'}}>
        <div style={{width:'100%',height:'100px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#ccc'}}><Loader /></div>
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> 
          </Placeholder>
          <Placeholder.Button variant="warinng" style={{backgroundColor:'#EC8106'}} xs={6} />
        </Card.Body>
      </Card>

      <Card style={{ width: '18rem' , margin:'10px'}}>
        <div style={{width:'100%',height:'100px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#ccc'}}><Loader /></div>
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> 
          </Placeholder>
          <Placeholder.Button variant="warinng" style={{backgroundColor:'#EC8106'}} xs={6} />
        </Card.Body>
      </Card>

      <Card style={{ width: '18rem' , margin:'10px'}}>
        <div style={{width:'100%',height:'100px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#ccc'}}><Loader /></div>
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> 
          </Placeholder>
          <Placeholder.Button variant="warinng" style={{backgroundColor:'#EC8106'}} xs={6} />
        </Card.Body>
      </Card>

      <Card style={{ width: '18rem' , margin:'10px'}}>
        <div style={{width:'100%',height:'100px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#ccc'}}><Loader /></div>
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> 
          </Placeholder>
          <Placeholder.Button variant="warinng" style={{backgroundColor:'#EC8106'}} xs={6} />
        </Card.Body>
      </Card>

    </div>
    </div>
  );
}

export default CardExample;