import './style.css'

const PreviewCard = () => {
  return (
    <div className='d-flex '>
      <span class="loader"></span>
    </div>
  )
}

export default PreviewCard