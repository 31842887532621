
import PDF from '../../assets/About/YOUNESSHEIKHLAR.pdf'

function PDFDownloadButton() {
 

  return (
    <>
      <a className='m-2 btn btn-success' href={PDF}>PDF</a>
    </>
  );
}

export default PDFDownloadButton;
